import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import starIcon from '../../../assets/images/star-icon.png'
import contact from '../../../assets/images/contact.png'
import AniLink from "gatsby-plugin-transition-link/AniLink";


// export const ContactUs = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm('gmail', 'template_5cafvh8', form.current, 'jglhnofqEgcUjRvy5')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//   };

//   return (
//     <form ref={form} onSubmit={sendEmail}>
//       <label>Name</label>
//       <input type="text" name="user_name" />
//       <label>Email</label>
//       <input type="email" name="user_email" />
//       <label>Message</label>
//       <textarea name="message" />
//       <input type="submit" value="Send" />
//     </form>
//   );
// };


const ContactForm = () => {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_dl07wyk', 'template_5cafvh8', form.current, 'jglhnofqEgcUjRvy5')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

    return (
        <section className="contact-area pt-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="consultation" /> 
                        Get in Touch
                    </span>
                    {/* <h2>Ready to Get Started?</h2> */}
                    {/* <p>Required fields are marked *</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            {/* <form id="contactForm" ref={form} onSubmit={sendEmail}> */}
                            <form ref={form} onSubmit={sendEmail}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Your name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" required placeholder="Your email address" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" className="form-control" required placeholder="Your phone number" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message..." />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        {/* <input type="submit" value="Send"></input> */}
                                        
                                        <button type="submit" className="default-btn" value="Send" >
                                            <i className="flaticon-tick"></i> 
                                            Send Message <span></span>
                                        </button>

                                        {/* <AniLink 
                                    paintDrip
                                    hex="#ff5d22"
                                    
                                    button type="submit" className="default-btn" value="Send"
                                    to="/confirmation" >
                                    <i className="flaticon-right"></i> 
                                    Send Message <span></span>
                                    </AniLink> */}
                                        
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm